
import { Component, Mixins, Prop } from 'vue-property-decorator';
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import DatePreview from '../ui/DatePreview.vue';
import { MyEventsMixin, AppHostnameMixin, BAIconsMixin, AdminAuthMixin } from '../../mixins';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { BestAthletesEventModel } from '../../models/bestAthletesEvent';
import { hasRole } from '../../directives/v-require-role';


@Component({
	components: { DatePreview, SideNavSectionTitle },
})
export default class EventsNavItems extends Mixins(MyEventsMixin, AppHostnameMixin, BAIconsMixin, AdminAuthMixin){
	@Prop({ default: false, type: Boolean }) mini: boolean;
	@Prop({ default: false, type: Boolean }) noAdmin: boolean;
	@Prop({ default: 3 }) limit: number;
	get RootEventDetailsRoute(): string{
		return `${Routes.AthleteProfilesManagement}${Routes.EventDetails}`
	}

	get MyEventsLimited(): BestAthletesEventModel[]{
		return this.MyEvents.slice(0, this.limit);
	}

	get NoEvents(): boolean{
		return this.MyEventsLimited.length === 0;
	}

	get ShowEvents(): boolean{
		if(this.AdminAppActive) return true;
		if(this.NoEvents === true){
			return false;
		}
		return this.NoEvents === false || this.EventAdminRoles.map(role => hasRole(role)).reduce((a,b) => a || b);
	}
}
