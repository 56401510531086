
import { Component, Prop, Mixins } from 'vue-property-decorator';
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import BALogoAdminSvg from '../svg/logos/BALogoAdminSvg.vue';
import { Route } from 'vue-router';
import * as AdminRoutes from '@/../types/constants/admin.routes';
import EventsNavItems from './EventsNavItems.vue';
import { BAIconsMixin, AdminAuthMixin } from '@/mixins';

@Component({
	components: {
		BALogoAdminSvg,
		EventsNavItems,
		SideNavSectionTitle,
	},
})
export default class AdminSideNav extends Mixins(BAIconsMixin, AdminAuthMixin) {
	@Prop({ default: true, type: Boolean }) value: boolean;
	@Prop({ default: 'black' }) color: string;
	mini = false;

	get TeamAdminDashboard(): Partial<Route>{
		return {
			name: AdminRoutes.TeamAdminDashboard,
		}
	}

	get OrganizationAdminDashboard(): Partial<Route>{
		return {
			name: AdminRoutes.OrganizationAdminDashboard,
		}
	}
	get SchoolSearchAdminDashboard(): Partial<Route>{
		return {
			name: AdminRoutes.SchoolSearchAdminDashboard,
		}
	}
	get BASchoolAdminDashboard(): Partial<Route>{
		return {
			name: AdminRoutes.BASchoolAdminDashboard,
		}
	}
	get CreateSchoolRoute(): Partial<Route>{
		return {
			name: AdminRoutes.SchoolCreate,
		}
	}

	
}
